var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Create-Plan-Map", style: { "--height": _vm.mapHeight } },
    [
      _c("div", {
        ref: "planMap",
        staticClass: "CPM",
        attrs: { id: "Create-Plan-Map" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }