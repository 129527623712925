import Vue from  'vue'
import overlayCreatePlanPoint from './overlayCreatePlanPoint'
// 自定义覆盖物
class RadarOverlayCreatPlanPoint extends BMapGL.Overlay {
  constructor(point,planDay) {
    super();
    this.point = point;
    this.planDay = planDay;
  }

  initialize(map) {
    this._map = map;
    // 构造子类
    let MessageConstructor = Vue.extend(overlayCreatePlanPoint)
    // 实例化组件
    let messageInstance = new MessageConstructor()
    messageInstance.planDay = this.planDay
    // 如果不传入选择器，将渲染为文档之外的的元素，你可以想象成 document.createElement()在内存中生成dom
    messageInstance.$mount()
    setTimeout(() => {
      map.getPanes().markerPane.appendChild(messageInstance.$el);
    }, 1000);
    
    this._div = messageInstance.$el;
    return messageInstance.$el;
  }
   
  draw() {
    // 根据地理坐标转换为像素坐标，并设置给容器 这里是因为我的图标设置的是 20 所以 一半是减去10
    const position = this._map.pointToOverlayPixel(this.point);  
    this._div.style.left = `${position.x - 8}px`;
    this._div.style.top = `${position.y - 45}px`;
  }
}

export default RadarOverlayCreatPlanPoint;