var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Overlay-Create-Plan-Point" }, [
    _c("span", { staticClass: "marks" }, [_vm._v("D" + _vm._s(_vm.planDay))]),
    _c("img", {
      attrs: { src: require("../../../assets/png/qiziPoint.png"), alt: "" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }