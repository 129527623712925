/**
 * 根据数组内的某个key分组
 * @param array 数组本身
 * @param key
 * @returns {*}
 */
export const groupBy = function (array, key) {
    const grouped = array.reduce((acc, obj) => {
        const code = obj[key];
        if (!acc[code]) {
            acc[code] = [];
        }
        acc[code].push(obj);
        return acc;
    }, {});
    return grouped;
}